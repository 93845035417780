import { Box } from "@mui/material";
import logo from '../assets/logo.png'


export default function Home() {

  
  return (
    <Box sx={{ width: '100vw', height: '100vh' , backgroundColor:'#000', display:'flex', alignItems:'center', justifyContent:'center'}}>
     <Box >
      <img src={logo} alt="logo"/>
     </Box>
    </Box>
  );
}
