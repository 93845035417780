// ----------------------------------------------------------------------

export default function Textfield(theme) {
  return {
    MuiOutlinedInput: {
      styleOverrides: {
        root : {
          '& fieldset': {
            border: '2px solid black',
          }
        }
      }
    },
  };
}
