import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
//
import App from "./App";

import { QrProvider } from "./provider";


//---------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    
      <HelmetProvider>
        <BrowserRouter >
          <QrProvider>
            <App />
          </QrProvider>
        </BrowserRouter>
      </HelmetProvider>
    
  </React.StrictMode>
);
