// Hospital Api url
export const GET_HOSPITAL_URL = "/qr"
export const GET_HOSPITAL_INFO_URL = "/hospital-information"
export const GET_SERVICES_URL = "/hk-maintenance"
export const POST_SERVICES_URL = '/hk-maintenance-request'

export const GET_FEEDBACK_QUESTION_URL = '/feedback-question'
export const POST_FEEDBACK_QUESTION_URL = '/feedback-response'

export const POST_CONTACT_URL = '/hospital-contact-form'

export const CALL_OPTION_URL = '/feedback-calloptions'