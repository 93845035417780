import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { QrContext } from "../provider";
import { useContext } from "react";
import { styled, Typography, Box, Button, Grid, ButtonGroup, List, ListItem, ListItemButton, ListItemText, FormGroup, FormControlLabel, Checkbox, iconButtonClasses, TextField, CircularProgress, Stack, Grow } from "@mui/material";

import { useNavigate } from "react-router-dom";

// assets
import hkImage from '../assets/hk_mainten.svg'
import mnImage from '../assets/maintenance.svg'
import { addServices, getServices } from "../api/auth";
import CustomSnackBar from "../components/snackbar";
import { ArrowBackIosNew } from "@mui/icons-material";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';



export default function HousekeepingAndMaintenance() {

  const { id } = useParams()
  const navigate = useNavigate();
  const { qrDetails, powerUsers, qrId } = useContext(QrContext);

  const [items, setItems] = useState(null);
  const [housekeepingServices, setHousekeepingServices] = useState([]);
  const [maintenanceServices, setMaintenanceServices] = useState([]);
  const [selectedService, setSelectedService] = useState([]);
  const [query, setQuery] = useState(null);
  const [selectedServices, setSelectedServices] = useState(null);
  const [housekeepingState, setHousekeepingState] = useState(true);
  const [maintenanceState, setMaintenanceState] = useState(false);
  const [housekeepingServiceState, setHousekeepingServiceState] = useState(false);
  const [maintenanceServiceState, setMaintenanceServiceState] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  console.log(qrDetails, powerUsers);

  const fetchData = async () => {

    try {
      const { data } = await getServices(qrDetails.hospitalId)
      if (data.success) {

        setHousekeepingServices(data.housekeeping.services)
        setMaintenanceServices(data.maintenance.services)

        // console.log(data);

      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
    }
  }

  useEffect(() => {
    fetchData();
  }, [])


  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    const requestedService = {
      hospitalId: qrDetails.hospitalId,
      serviceId: selectedService.serviceId,
      subServices: items,
      query,
      qrId: qrDetails.locationId,
    }
    try {
      const { data } = await addServices(requestedService)
      console.log({ data });
      if(data.success)
      {
        navigate(`/${id}/authentication`, { state: { requestId:data.requestId, requestType: 'SERVICE' } })
      }
      setIsLoading(false)
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsLoading(false)
    }
  };

  const handleCheck = (id, checked) => {
    console.log('check', id, checked);
    setItems((prevServices) =>
      prevServices.map((service) =>
        service.subServiceId === id ? { ...service, checked } : service
      )
    )
  };


  const handleServices = (st, item) => {
    if (st) {
      setHousekeepingServiceState(true)
      setMaintenanceServiceState(false)
    }
    else {
      setMaintenanceServiceState(true)
      setHousekeepingServiceState(false)
    }
    setItems(item.subServices);
    setSelectedService(item)
    setHousekeepingState(false)
    setMaintenanceState(false)
    setSelectedServices(item)
  };

  const handlePrev = (st) => {
    if (st) {
      setHousekeepingState(true)
      setMaintenanceState(false)
    }
    else {
      setHousekeepingState(false)
      setMaintenanceState(true)
    }
    setMaintenanceServiceState(false)
    setHousekeepingServiceState(false)
  };

  const handleSwitch = (st) => {
    if (st) {
      setHousekeepingState(true)
      setMaintenanceState(false)
      setHousekeepingServiceState(false)
      setMaintenanceServiceState(false)
    }
    else {
      setMaintenanceState(true)
      setHousekeepingState(false)
      setHousekeepingServiceState(false)
      setMaintenanceServiceState(false)
    }
  }
  // sx={{backgroundColor:'#454'}}
  // console.log(HouseKeepingData)
  return (

    <Box sx={{ width: '100%' }}>


      {housekeepingServices.length || maintenanceServices.length ? (
        <Grid container mt={2}  >
          <Box display='flex' justifyContent='center' alignItems='center' width='100%' >
            {(housekeepingState || maintenanceState) &&
              <ButtonGroup aria-label="outlined button group" sx={{ backgroundColor: '#828282', borderRadius:12 }}  variant="text" color="dyn1">
                <Button onClick={() => handleSwitch(true)}><Typography sx={{ fontWeight: 600, fontSize: '20px', py: 2, px: 1, borderRadius: 12, backgroundColor: housekeepingState ? '#fff' : '', color: housekeepingState ? '#000' : '#fff' }}>Housekeeping</Typography></Button>
                <Button onClick={() => handleSwitch(false)}><Typography sx={{ fontWeight: 600, fontSize: '20px', py: 2, px: 1, borderRadius: 12, backgroundColor: maintenanceState ? '#fff' : '', color: maintenanceState ? '#000' : '#fff' }}>Maintenance</Typography></Button>
              </ButtonGroup>
            }
          </Box>
          {maintenanceState && (

            <Grid container display='flex' justifyContent='center' alignItems='center' rowSpacing={2} mt={4} mb={{ sm: 7 }}>
              <Grid item xs={12} sm={6} md={4} >
                <Stack spacing={4} display='flex' justifyContent='center' alignItems='center' flexDirection='column' >
                <Grow style={{ transformOrigin: '0 0 0' }} in={true}  {... { timeout: 1000 } }><img src={mnImage} alt="icon" width={240} height={180} /></Grow>
                  <List component="nav" aria-label="secondary mailbox folder">
                    {maintenanceServices.map((item, ind) => (
                      <ListItemButton
                        onClick={() => handleServices(false, item)}
                      >
                        <Typography variant="h4">{item.serviceTitle}</Typography>
                      </ListItemButton>
                    ))}
                  </List>
                </Stack>
              </Grid>

            </Grid>


          )}
          {maintenanceServiceState && (
            <Grid container display='flex' flexDirection='column'>


              <Box ml={2} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#000', width: '200px', borderRadius: 12 }} onClick={() => { setMaintenanceServiceState(false); setMaintenanceState(true) }}>
                <ArrowBackIcon sx={{ m: 2, cursor: 'pointer', color: '#fff' }}  />
                <Typography color='#fff' variant="h6">
                  Maintenance
                </Typography>
              </Box>

              <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }} >
                <img src={mnImage} alt="icon" width={240} height={180} />
                <Typography variant="h6" sx={{ backgroundColor: '#B3D2FB', borderRadius: 12, px: 2, py: 1 }}>
                  {selectedServices.serviceTitle.toUpperCase()}
                </Typography>
                <List>
                  <FormGroup>
                    {selectedServices.subServices.map((item, ind) => (
                      <ListItem disablePadding key={ind}>
                        <FormControlLabel control={<Checkbox name={item.subServiceTitle}
                          checked={item.subServiceChecked}
                          onChange={(e) => handleCheck(item.subServiceId, e.target.checked)} />} label={item.subServiceTitle} />
                      </ListItem>
                    ))}
                  </FormGroup>
                </List>
                <TextField id="outlined-basic" label="Enter unlisted services here" variant="outlined" name="query" onChange={(e) => { setQuery(e.target.value) }} />
                <Button variant='contained' onClick={handleSubmit} sx={{ borderRadius: 8 }}>{isLoading ? <CircularProgress /> : 'Submit'}</Button>
              </Stack>
            </Grid>
          )}
          {housekeepingState && (
            <Grid container display='flex' justifyContent='center' alignItems='center' rowSpacing={2} mt={4} mb={{ xs: 10, sm: 26 }}>
              <Grid item xs={12} sm={6} md={4} >
                <Stack spacing={4} display='flex' justifyContent='center' alignItems='center' flexDirection='column' >
                <Grow style={{ transformOrigin: '0 0 0' }} in={true}  {... { timeout: 1000 } }><img src={hkImage} alt="icon" width={180} height={180} /></Grow>
                  <List component="nav" aria-label="secondary mailbox folder">
                    {housekeepingServices.map((item, ind) => (
                      <ListItemButton
                        onClick={() => handleServices(true, item)}
                      >
                        <Typography variant="h4">{item.serviceTitle}</Typography>
                      </ListItemButton>
                    ))}
                  </List>
                </Stack>
              </Grid>

            </Grid>
          )}
          {housekeepingServiceState && (
            <Grid container display='flex' flexDirection='column'>


              <Box ml={2} sx={{ display: 'flex', alignItems: 'center', backgroundColor: '#000', width: '200px', borderRadius: 12 }} onClick={() => { setHousekeepingServiceState(false); setHousekeepingState(true) }}>
                <ArrowBackIcon sx={{ m: 2, cursor: 'pointer', color: '#fff' }}  />
                <Typography color='#fff' variant="h6">
                  Housekeeping
                </Typography>
              </Box>

              <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }} >
                
                <img src={hkImage} alt="icon" width={180} height={180} />
                <Typography variant="h6" sx={{ backgroundColor: '#B3D2FB', borderRadius: 12, px: 2, py: 1 }}>
                  {selectedServices.serviceTitle.toUpperCase()}
                </Typography>
                <List>
                  <FormGroup>
                    {selectedServices.subServices.map((item, ind) => (
                      <ListItem disablePadding key={ind}>
                        <FormControlLabel control={<Checkbox name={item.subServiceTitle}
                          checked={item.subServiceChecked}
                          onChange={(e) => handleCheck(item.subServiceId, e.target.checked)} />} label={item.subServiceTitle} />
                      </ListItem>
                    ))}
                  </FormGroup>
                </List>
                <TextField id="outlined-basic" label="Enter unlisted services here" variant="outlined" name="query" onChange={(e) => { setQuery(e.target.value) }} />
                <Button variant='contained' onClick={handleSubmit} sx={{ borderRadius: 8 }}>{isLoading ? <CircularProgress /> : 'Submit'}</Button>
              </Stack>
            </Grid>
          )}

        </Grid>
      ) : (
        <Grid display='flex' justifyContent='center' mt={16}>
          <CircularProgress />
        </Grid>
      )}
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </Box>)
}
