import * as React from 'react';
import Box from '@mui/material/Box';
import footerImage from '../../assets/footer_logo.png'
import { Stack, Typography } from '@mui/material';

export default function AppFooter() {
  return (
    <Stack spacing={2}
      sx={{ display: 'flex', alignItems: 'center', position:'fixed' , left:0, bottom:20 , width:'100vw'}}
    >
      <img src={footerImage} alt='img' width={240} />
      <Typography color='#B7B7B7' variant='body1'> © Tellercomm {new Date().getFullYear()}</Typography>
    </Stack>

  );
}
