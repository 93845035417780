
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, CircularProgress, Grid, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled } from "@mui/material";

import { addQuery, getHospitalInfo } from "../api/auth";
import CustomSnackBar from "../components/snackbar";
import { QrContext } from "../provider";
import hospitalLogo from '../assets/hospital_logo_temp.png'
import infoImage from '../assets/In.svg'
import amenity1 from '../assets/am1.png'
import amenity2 from '../assets/am2.png'
import amenity3 from '../assets/am3.png'
import Carousel from "react-material-ui-carousel";

export default function Hospitalinfo() {
  const { id } = useParams()
  const { qrDetails, qrId } = useContext(QrContext);
  const [inputs, setInputs] = useState({})

  const [hospitalInfo, setHospitalInfo] = useState(null)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false)

  const settings = {
    dots: true,

  }

  const fetchData = async () => {
    try {
      const { data } = await getHospitalInfo(qrDetails.hospitalId)
      if (data.success) {
        setHospitalInfo(data.hospitalInformation)
        // console.log(data);
      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }
    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
    }
  }
  const items = [
    {
      name: "Random Name #1",
      description: "Probably the most random thing you have ever seen!"
    },
    {
      name: "Random Name #2",
      description: "Hello World!"
    }
  ]
  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(false)

    try {
      const { data } = await addQuery({ ...inputs, hospitalId: qrDetails.hospitalId })
      console.log({ data });
      if (data.success) {
        navigate(`/${id}/authentication`, { state: { requestId: data.requestId, requestType: 'CONTACT' } })
      }
      setIsLoading(false)
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchData();
  }, [])

  return (
    <>

      {hospitalInfo ?
        <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mx: 2, my: 2 }}>
            <img src={hospitalInfo.Hospitallogo} alt="logo" width={100} />
            <Stack>
              <Typography variant="h4">Welcome to {hospitalInfo.hospitalName}</Typography>
              <Typography variant="subtitle1">Your trusted wellness partner</Typography>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <img src={infoImage} alt="img" width={280} />
          </Box>
          <Stack sx={{ backgroundColor: '#D9D9D9', borderRadius: 3, m: 2, p: 2 }}>
            <Typography variant="h5">About us</Typography>
            <Typography variant="subtitle1">{hospitalInfo.aboutUs}</Typography>
          </Stack>
          <Stack sx={{ mx: 4, my: 2 }}>
            <Typography variant="h5">Specialties</Typography>
            <Grid container rowSpacing={2} columnSpacing={2} mt={1}>
              {hospitalInfo.specialties && hospitalInfo.specialties.map((item, ind) =>
                <Grid item xs={6} md={8} key={ind}>
                  <Typography sx={{ backgroundColor: '#D9D9D9', borderRadius: 4, py: 1 }} align="center">{item.specialty_name}</Typography>
                </Grid>
              )}
            </Grid>

          </Stack>
          <Stack sx={{ mx: 4, my: 2 }}>
            <Typography variant="h5">Amenities</Typography>
            <Stack spacing={2} sx={{ backgroundColor: '#D9D9D9', borderRadius: 4, width:'100%' }}>
              {hospitalInfo.Amenities && hospitalInfo.Amenities.map((item) =>
                <img src={item.imageUrl} alt="img" height={200} style={{ borderRadius: '8%', margin:8 }} />
              )}
            </Stack>
          </Stack>
          <Stack sx={{ mx: 4, my: 2 }}>
            <Typography variant="h5">Doctors</Typography>
            <Table aria-label="simple table" sx={{ backgroundColor: '#D9D9D9', borderRadius: 2 }}>

              <TableRow>
                <TableCell align="center" sx={{ borderBottomColor: '#fff', borderRightColor: '#fff' }}><b>Doctor</b></TableCell>
                <TableCell align="center" sx={{ borderBottomColor: '#fff', borderRightColor: '#fff' }}><b>Specialty</b></TableCell>
              </TableRow>

              <TableBody>
                {hospitalInfo.doctors && hospitalInfo.doctors.map((item) =>
                  <TableRow>
                    <TableCell align="center" sx={{ borderBottomColor: '#fff', borderRightColor: '#fff' }}>{item.doctor_name}</TableCell>
                    <TableCell align="center" sx={{ borderBottomColor: '#fff', borderRightColor: '#fff' }}>{item.specialty_name}</TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Stack>
          <Stack sx={{ mx: 4, my: 2 }}>
            <Typography variant="h5">What's new</Typography>
            <Box sx={{ mt: 2, }}>
              <Carousel sx={{ width:'100%'}}>
                {
                  hospitalInfo.WhatsNew && hospitalInfo.WhatsNew.map((item, i) => (
                    <Box key={i} >

                      <img src={item.imageUrl} alt="img" width={320} height={220} />
                    </Box>))}
              </Carousel>
            </Box>
          </Stack>
          <Stack spacing={2} sx={{ mx: 4, my: 2 }} component="form" onSubmit={handleSubmit}>
            <Typography variant="h5">Contact us</Typography>
            <TextField id="outlined-multiline-flexible"
              label="Name"
              name='name'
              required
              onChange={handleInputChange}
            />
            <TextField id="outlined-multiline-flexible"
              label="Email"
              required
              name='email'

              onChange={handleInputChange}
            />
            <TextField id="outlined-multiline-flexible"
              label="Subject"
              required
              name='subject'
              onChange={handleInputChange}
            />
            <TextField id="outlined-multiline-flexible"
              label="Message"
              multiline
              required
              name='message'
              minRows={4}
              onChange={handleInputChange}
            />
            <Button variant='contained' sx={{ backgroundColor: '#000' }} type='submit'>Submit</Button>

          </Stack>
        </Box>
        :
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '70vh' }}>
          <CircularProgress />
        </Box>
      }

      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </>
  );
}
