import { Navigate,  useRoutes } from "react-router-dom";

import SimpleLayout from "./layout/simple/SimpleLayout";
import Page404 from "./pages/Page404";
import Landing from "./pages/Landing";
import Hospitalinfo from "./pages/Hospitalinfo";

import HousekeepingAndMaintenance from "./pages/HouseKeepingAndMaintenance";
import Authentication from "./pages/Authentication";
import Feedback from "./pages/Feedback";
import FeedbackQuestions from "./pages/feedbackQuestions";
import Home from "./pages/Home";
import SuccessForm from "./pages/SuccessForm";

export default function Router() {
  const routes = useRoutes([
    {
      path: '/:id',
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to={`features`} />, index: true },
        { path: "features", element: <Landing /> },
        {
          path: "hospital-information",
          element: <Hospitalinfo />,
        },
        {
          path: "housekeeping-maintenance",
          element: <HousekeepingAndMaintenance />,
        },
        {
          path: "feedback",
          element: <Feedback />,
        },
        {
          path: "feedback-questions",
          element: <FeedbackQuestions />,
        },
        {
          path: "authentication",
          element: <Authentication />,
        },

        {
          path: "success",
          element: <SuccessForm />,
        },
        
      ],
    },
    {
      path: "/",
      element: <Home />
    },
   



    // {
    //   path: "/feedback",
    //   element: <Feedback />,
    // },

    // {
    //   path: "/results",
    //   element: <FeedbackResults />,
    // },
    // {
    //   path: "/feedback/thankyou",
    //   element: <ThankYou />,
    // },
    // {
    //   path: "/requests/thankyou",
    //   element: <ThankYouRequest />,
    // },
    // {
    //   path: "/services",
    //   element: <Services />,
    // },
    // {
    //   path: "/feedbackquestionnew",
    //   element: <FeedbackQuestions />,
    // },
  ]);

  return routes;
}
