import axios from 'axios'
import { useEffect, useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import OtpInput from 'react-otp-input';
import OTPInput, { ResendOTP } from "otp-input-react";

// mui -------------------------
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack, TextField, Typography, Container, LinearProgress } from "@mui/material";

// assets
import logo from '../assets/logo.svg'
import subTic from '../assets/subTic.png'
import hkMain from '../assets/hk_main.jpg'
import feedback from '../assets/fb.png'

// api
import { firebaseAuth } from "../api/config";


// icons--------------------------------
import { FaRegHospital } from "react-icons/fa";
import { RiTodoLine, RiFeedbackLine } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
//router dom
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useContext } from 'react';
import { QrContext } from '../provider';
import { addFeedback, addServices, postContact, resendOTP, sendOTP, verifyOTP } from '../api/auth';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import ResponsiveAppBar from '../components/Appbar';
import AppFooter from '../components/BottomNavigation';
import CustomSnackBar from '../components/snackbar';
import loadingImage from '../assets/loading.png'
import { ArrowBack } from '@mui/icons-material';

export default function Authentication() {
  const { qrDetails, qrId } = useContext(QrContext);
  const { id } = useParams()

  const navigate = useNavigate();
  const location = useLocation();
  console.log(location.state);
  const [inputs, setInputs] = useState({});
  const [viewOtpForm, setViewOtpForm] = useState(false)
  const [result, setResult] = useState(null);
  const [otp, setOtp] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isButtonLoading, setIsButtonLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [countdown, setCountdown] = useState(false)
  const [timeDuration, setTimeDuration] = useState(120);
  const [countdownText, setCountdownText] = useState();

  const handleClose = () => {
    setOpen(false);
    navigate(`/${qrDetails.locationId}`)
  };


  const handleCallback = () => {
    navigate(`/${qrDetails.locationId}`)
  }


  const handleChange = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value })
  }

  const handlePrev = () => {
    navigate(-1)
  }
  useEffect(() => {
    const minutes = Math.floor(timeDuration / 60);
    const seconds = timeDuration % 60;
    setCountdownText(`${minutes}:${seconds < 10 ? "0" + seconds : seconds}`)
  }, [timeDuration]);
  useEffect(() => {
    if (viewOtpForm) {

      let intervalId = setInterval(() => {
        setTimeDuration((prev) => {
          const newTimeDuration = prev - 1;
          const percentage = Math.ceil((newTimeDuration / timeDuration) * 100);

          if (newTimeDuration === 0) {
            clearInterval(intervalId);
            intervalId = null;
          }
          return newTimeDuration;
        });
      }, 1000);
      return () => {
        clearInterval(intervalId);
        intervalId = null;
      };
    }
  }, [countdown])
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsButtonLoading(true)
    if (inputs.phone === "" || inputs.phone.length < 10) return;
    // console.log('sdsd', inputs);
    try {
      const { data } = await sendOTP({ ...inputs, phone: `91${inputs.phone}`, requestId: location.state.requestId, requestType: location.state.requestType })
      console.log({ data });
      setSnackbarMessage({ msg: "OTP requested successfully", success: true });
      setSnackbarOpen(true);
      setCountdown(true);
      setViewOtpForm(true)
      setIsButtonLoading(false)
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsButtonLoading(false)
    }
  };

  const handleResendOTP = async (event) => {
    event.preventDefault();
    setIsButtonLoading(true)
    if (inputs.phone === "" || inputs.phone.length < 10) return;
    // console.log('sdsd', inputs);
    try {
      const { data } = await resendOTP({ ...inputs, phone: `91${inputs.phone}`, requestId: location.state.requestId, requestType: location.state.requestType })
      console.log({ data });
      setSnackbarMessage({ msg: "OTP requested successfully", success: true });
      setSnackbarOpen(true);
      setCountdown(true);
      setIsButtonLoading(false)
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsButtonLoading(false)
    }
  };

  const handleOtp = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      const { data } = await verifyOTP({ otp, phone: `91${inputs.phone}`, requestId: location.state.requestId, requestType: location.state.requestType })
      if (data.success) {
        setSnackbarMessage({ msg: "OTP verified successfully", success: false });
        setSnackbarOpen(true);
        navigate(`/${qrDetails.locationId}/success`, { state: { requestType: location.state.requestType, requestId: location.state.requestId, warning: location.state.warning } })
      }
      else{
        setIsLoading(false)
      }
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsLoading(false)
    }

  }

  // sx={{backgroundColor:'#454'}}
  return (
    <Box sx={{ height: '100%' }} >
      <Grid display='flex' alignItems='center' flexDirection='column' height='100%'>
        {isLoading ?
          <Box sx={{ width: '100%', height: '100%' }}>
            <LinearProgress />
            <Stack spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
              <img src={loadingImage} alt='img' width={300} />
              <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h4'>Loading...</Typography>
                <Typography variant='h6'>Thanks for your patience!</Typography>
              </Stack>
            </Stack>
          </Box>
          :
          <Grid display='flex' flexDirection='column' mt={4}>
            <Button variant='contained' sx={{ backgroundColor: '#000', borderRadius: 8, width: 160, ml: 2, mb: 2 }} startIcon={<ArrowBack color='inherit' />} onClick={handlePrev}>{location.state.requestType}</Button>
            <Grid container display='flex' justifyContent='center' alignItems='center'>
              <Typography sx={{ fontWeight: 600, fontSize: '20px', m: 2 }}>OTP Authentication</Typography>

              <Box component="form" noValidate autoComplete="off" onSubmit={viewOtpForm ? handleOtp : handleSubmit} >

                <Stack display='flex' justifyContent='center' alignItems='center' flexDirection='column' spacing={2}>
                  {!viewOtpForm &&
                    <>
                      <TextField
                        label="Name"
                        variant="outlined"
                        name='name'
                        type='text'
                        value={inputs.name}
                        onChange={handleChange}
                        fullWidth
                      />
                      <TextField
                        label="Phone"
                        variant="outlined"
                        name='phone'
                        type='tel'
                        inputProps={{ maxLength: 10 }}
                        value={inputs.phone}
                        onChange={handleChange}
                        fullWidth
                      /></>}
                  <div id="recaptcha-container"></div>
                  {viewOtpForm &&
                    <Stack display='flex' justifyContent='center' alignItems='center' flexDirection='column' spacing={2}>
                      <Typography align='center'>{countdownText}</Typography>
                      <Typography>Please enter the OTP within 2 minutes</Typography>
                      <Box pt={1} >
                        <OTPInput value={otp} onChange={setOtp} autoFocus OTPLength={4} otpType="number" disabled={false} inputStyles={{ width: 30, height: 30 }} secure />
                      </Box>
                    </Stack>}
                  <Box display='flex' justifyContent='space-between' alignItems='center' width='240px'>
                    {viewOtpForm ? <Stack spacing={2} width={240}>
                      <Button variant='contained' type='submit' fullWidth sx={{ backgroundColor: "#000", color: '#fff', py: 1 }}>{isButtonLoading ? <CircularProgress color='inherit' size={24} /> : 'Verify OTP'}</Button>
                      <Button variant='contained' onClick={handleResendOTP} fullWidth sx={{ backgroundColor: "#000", color: '#fff', py: 1 }}>{isButtonLoading ? <CircularProgress color='inherit' size={24} /> : 'Resend OTP'}</Button>

                    </Stack> :
                      <Button variant='contained' type='submit' fullWidth sx={{ backgroundColor: "#000", color: '#fff', py: 1 }}>{isButtonLoading ? <CircularProgress color='inherit' size={24} /> : 'Send OTP'}</Button>
                    }
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>}
      </Grid>


      <Dialog open={open} onClose={handleClose} display='flex' justifyContent='center' alignItems='center'>
        {location.state.requestType === 'SERVICE' &&

          <DialogContent >
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Box><img src={subTic} alt='img' width='80px' height='80px' /></Box>
              <Typography sx={{ fontWeight: 600, fontSize: '24px', mt: 1 }}>Thank you very much!</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px' }} align='center'>Your request has been  forwarded to the concerned department and will be attended to shortly.</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', mt: 3 }} align='center'>Any further requests, kindly let us know.</Typography>
              <Typography sx={{ fontWeight: 600, fontSize: '16px' }}>We will be happy to help.</Typography>
              <Button variant='contained' onClick={handleClose} sx={{ mt: 2 }}>Continue</Button>
            </Box>
          </DialogContent>
        }
        {location.state.requestType === 'FEEDBACK' &&

          <DialogContent >
            <Box display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
              <Box><img src={subTic} alt='img' width='80px' height='80px' /></Box>
              <Typography sx={{ fontWeight: 600, fontSize: '24px', mt: 1 }}>Thank you very much!</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px' }} align='center'>Your feedback has been saved and help us improve our services even more closely to your desires and needs.</Typography>
              <Typography sx={{ fontWeight: 400, fontSize: '16px', mt: 3 }} align='center'>Would you like us to call you regarding your feedback?</Typography>
              <Box sx={{ display: "flex", justifyContent: 'space-between', margin: 3, width: '200px' }}>
                <Button id='Yes' onClick={handleCallback} variant="contained" color="success">
                  <Typography fontFamily="Monument Extended">Yes!</Typography>
                </Button>
                <Button id='No' onClick={handleCallback} variant="contained" color="error">
                  <Typography fontFamily="Monument Extended">No!</Typography>
                </Button>
              </Box>
            </Box>
          </DialogContent>
        }
      </Dialog>
      <AppFooter />
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </Box>
  );
}
