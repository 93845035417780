import * as React from 'react';
import { useEffect, useState, useContext } from "react";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";

import { blue, blueGrey } from "@mui/material/colors";
import { styled } from '@mui/material/styles';
import { Box, Grid, Typography, StyledRating, Number, Rating, TextField, Button, useMediaQuery, Stack, CircularProgress } from "@mui/material";

import { addFeedback, getFeedbackQuestion, postFeedbackQuestion } from "../api/auth";
import { QrContext } from "../provider";
import CustomSnackBar from "../components/snackbar";
import AppFooter from "../components/BottomNavigation";
//Rating reactions
import PropTypes from 'prop-types';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';


const FeedbackQuestions = () => {
  const { id } = useParams()
  const { state } = useLocation();
  const { qrDetails, qrId } = useContext(QrContext);
  const navigate = useNavigate();
  const [feedbackquestions, setFeedbackQuestions] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  const [inputs, setInputs] = useState({ suggestion: '' })
  const [response, setResponse] = useState(null)
  // console.log((qrDetails.hospitalId));

  const fetchData = async () => {
    try {
      const { data } = await getFeedbackQuestion(state.id)
      if (data.success) {
        console.log({ data });
        setFeedbackQuestions(data.feedbackQuestions.questions.filter((fb) => fb.sectionTypeId === state.selectedSection.sectionTypeId))
        setResponse(data.feedbackQuestions.questions.filter((fb) => fb.sectionTypeId === state.selectedSection.sectionTypeId).map((item) => ({ ...item, rating: '', comments: '', })))
      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
    }
  }

  const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
      color: theme.palette.action.disabled
    },
  }));

  const customIcons = {
    1: {
      icon: <SentimentVeryDissatisfiedIcon color="error" style={{ width: 50, height: 50 }} />,
      label: 'Very Dissatisfied',
    },
    2: {
      icon: <SentimentDissatisfiedIcon color="error" style={{ width: 50, height: 50 }} />,
      label: 'Dissatisfied',
    },
    3: {
      icon: <SentimentSatisfiedIcon color="warning" style={{ width: 50, height: 50 }} />,
      label: 'Neutral',
    },
    4: {
      icon: <SentimentSatisfiedAltIcon color="success" style={{ width: 50, height: 50 }} />,
      label: 'Satisfied',
    },
    5: {
      icon: <SentimentVerySatisfiedIcon color="success" style={{ width: 50, height: 50 }} />,
      label: 'Very Satisfied',
    },
  };

  function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
  }

  IconContainer.propTypes = {
    value: PropTypes.number.isRequired,
  };


  const handleChange = (e, id) => {
    console.log(response, feedbackquestions, id, e.target.name, e.target.value);
    const tempInputs = [...response];
    const inp = tempInputs.find(
      a => a.questionId === id
    );
    if (e.target.name === 'rating') {
      inp.rating = e.target.value;
    }
    if (e.target.name === 'comments') {
      // console.log('sds');
      inp.comments = e.target.value;
    }
    setResponse(tempInputs);
    // event.preventDefault();
  }

  const handleInputChange = (e) => {
    // console.log(e.target.name, e.target.value);
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  }
  const checkResponse = () => {
    let flag = false
    flag = response.some((item) => item.rating < 3)
    return flag;
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    // console.log(response.some((item) => item.rating === ""));
    if(response.some((item) => item.rating === ""))
    {
      setSnackbarMessage({ msg: 'Please answer every question', success: false });
      setSnackbarOpen(true);
      return false;
    }
    setIsLoading(false)
    const fbresponse = {
      hospitalId: qrDetails.hospitalId,
      responses: response,
      suggestion: inputs.suggestion
    }
    // console.log({fbresponse});
    try {
      const { data } = await addFeedback(fbresponse)
      console.log({ data });
      if(data.success)
      {
        navigate(`/${id}/authentication`, { state: { requestId:data.requestId, requestType: 'FEEDBACK', warning:checkResponse() } })
      }
      setIsLoading(false)
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsLoading(false)
    }   
  }


  useEffect(() => {
    fetchData()
  }, [])


  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack spacing={2} m={2}>
        <Typography variant='h5'>Give your feedback</Typography>
        <Typography >Welcome to our feedback page. If you would like to send us your views, rate our services and click 'Submit'.</Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant='h6' sx={{backgroundColor:'blue', color:'white', borderRadius: 8, px: 4, py: 1, maxWidth: 150 }} >{state.selectedSection.sectionTypeName.charAt(0).toUpperCase() + state.selectedSection.sectionTypeName.slice(1)}</Typography>
        </Box>
        {feedbackquestions?.map((item, ind) =>
          <Stack  spacing={1}>
            <Typography variant='h6'>{`${ind + 1}.${item.question}`}</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <StyledRating
                name="rating"
                value={response[ind].rating}
                IconContainerComponent={IconContainer}
                getLabelText={(value) => customIcons[value].label}
                highlightSelectedOnly
                onChange={(e) => handleChange(e, item.questionId)}
              />
            </Box>

          </Stack>
        )}
        <Typography variant='h6'>What are the things you feel we should improve upon, and
          do you have any other recommendations or concerns?</Typography>
        <Stack  spacing={2} component='form' onSubmit={handleSubmit}>
          <TextField id="outlined-multiline-flexible"
            label="Message"
            multiline
            name='suggestion'
            minRows={4}
            onChange={handleInputChange}
          />
          <Button variant='contained' sx={{ backgroundColor: '#000' }} type='submit'>{isLoading ? <CircularProgress /> : 'Submit'}</Button>
        </Stack>

      </Stack>
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
      {/* <AppFooter /> */}
    </Box>
  );
}

export default FeedbackQuestions;