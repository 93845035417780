import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';

import { Button, Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { QrContext } from "../provider";
import { dark } from '@mui/material/styles/createPalette';
import { useContext } from 'react';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CustomSnackBar from '../components/snackbar';
import AppFooter from '../components/BottomNavigation';
import feedbackImage from '../assets/feedback.svg'

const Item = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  // padding: theme,
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


export default function Feedback() {
  const {id} = useParams()
  const navigate = useNavigate()
  const { feedbackSection, qrDetails, qrId } = useContext(QrContext);
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)
  console.log(qrDetails);
 
  const handleSubmit = (item) => {
    navigate(`/${id}/feedback-questions`, { state: { selectedSection : item, id: qrDetails.hospitalId } })
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack pt={4} spacing={4} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <img src={feedbackImage} alt='img' width={300} />
        <Typography variant='h6'>Select a category to give feedback</Typography>

        <Grid container rowSpacing={2} >
          {feedbackSection?.map((item, index) =>
            <Grid item xs={6} md={8}>
              <Item>
                <Button variant='contained' sx={{ borderRadius: 8 ,px:4, py:1}} onClick={() => handleSubmit(item)}>{item.sectionTypeName.charAt(0).toUpperCase() + item.sectionTypeName.slice(1)}</Button>
              </Item>
            </Grid>
          )
          }
        </Grid>
      </Stack>
      {/* <AppFooter /> */}

      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </Box>
  );
}