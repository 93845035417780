import { createApiConfig } from "./config";
import { GET_FEEDBACK_QUESTION_URL, GET_HOSPITAL_URL, GET_SERVICES_URL, POST_SERVICES_URL, POST_FEEDBACK_QUESTION_URL, GET_HOSPITAL_INFO_URL, POST_CONTACT_URL, CALL_OPTION_URL} from "./routes";


const apiConfig = createApiConfig();



/**
 * @desc  Auth User API Service
 * @param  {string} mobile
 */

export const sendOTP = (inputs) => {
  return apiConfig.post(`otp/send`, inputs);
};

export const resendOTP = (inputs) => {
  return apiConfig.post(`otp/resend`, inputs);
};

export const verifyOTP = (inputs) => {
  return apiConfig.post(`otp/verify`, inputs);
};


export const getHospitalDetails = (path) => {
  return apiConfig.get(`${GET_HOSPITAL_URL}/${path}` );
};

export const getHospitalInfo = (id) => {
  return apiConfig.get(`${GET_HOSPITAL_INFO_URL}/${id}` );
};

export const getFeedbackQuestion = (id) => {
  return apiConfig.get(`${GET_FEEDBACK_QUESTION_URL}/${id}` );
};

export const postFeedbackQuestion = (inputs) => {
  return apiConfig.post(POST_FEEDBACK_QUESTION_URL, inputs);
};
export const addQuery = (inputs) => {
  return apiConfig.post(`${POST_CONTACT_URL}`, inputs);
};
export const getServices = (id) => {
  return apiConfig.get(`${GET_SERVICES_URL}/${id}` );
};

export const addServices = (inputs) => {
  return apiConfig.post(POST_SERVICES_URL, inputs);
};

export const addFeedback = (inputs) => {
  return apiConfig.post(POST_FEEDBACK_QUESTION_URL, inputs);
};

export const callOption = (inputs, hospitalId, requestId) => {
  return apiConfig.post(`${CALL_OPTION_URL}/${hospitalId}/${requestId}`, inputs);
};