import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Typography, MenuItem, Menu, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Divider } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
import logo from '../../../assets/logo.png'
import logo1 from '../../../assets/logo1.svg'
import tellercommIcon from '../../../assets/icon.svg'

// components
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Iconify from '../../../components/iconify';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useState } from 'react';
import { QrContext } from '../../../provider';
import sidebarIcon from '../../../assets/sidebar.png'
const NAV_WIDTH = 240;

const HEADER_MOBILE = 64;

const HEADER_DESKTOP = 70;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: 'none',
  backgroundColor: '#000',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};


export default function Header({ onOpenNav }) {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false);
  const {id} = useParams()
  const LOCAL_ROUTES = [
    {
      name:'Home',
      route:`/${id}/features`
    },
    {
      name:'Hospital information',
      route:`/${id}/hospital-information`
    },
    {
      name:'Houskeeping & maintenance',
      route:`/${id}/housekeeping-maintenance`
    },
    {
      name:'Feedback',
      route:`/${id}/feedback`
    }
  ]
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const { qrId } = useContext(QrContext)


  return (
    <StyledRoot>
      <StyledToolbar>
        <Stack py={3} pl={2} sx={{cursor:'pointer'}} onClick = {() => navigate(`/${id}/features`)}>
          <img src={logo1} alt="logo" width={110} height={25} />
          <Typography variant='caption' align='right'>Powered by TELLERCOMM</Typography>
        </Stack>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{
            xs: 0.5,
            sm: 1,
          }}
        >
          <IconButton
            onClick={toggleDrawer(true)}
            size="large"
            sx={{
              mr: 1,
              color: '#fff',

            }}
          >
            <MenuIcon fontSize='inherit' />
            {/* <Iconify icon="material-symbols:menu" height={20} /> */}
          </IconButton>
          <Drawer
            PaperProps={{ sx: { width: "90%" , backgroundColor: "#D9D9D9",} }}
            anchor='right'
            open={open}
            onClose={toggleDrawer(false)}
          >
           <Box py = {2} px = {2} sx={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
            <img src={sidebarIcon} alt='ico' width={25} height={25}/>
            <CloseIcon  onClick = {toggleDrawer(false)}/>
           </Box>
            <Divider variant="middle" sx={{ background: "#000"}}/>
            <List>
                <ListItem  disablePadding>
                  <ListItemButton onClick={() => {navigate(`/${id}/features`); setOpen(false)}}>
                    <Typography>Home</Typography>
                    {/* <ListItemText primary={text} /> */}
                  </ListItemButton>
                </ListItem>
                {qrId?.modules.some((item) => item.moduleName === "hospitalinformation" && item.licenseStatus === "active") &&
       
                <ListItem  disablePadding>
                  <ListItemButton onClick={() => {navigate(`/${id}/hospital-information`); setOpen(false)}}>
                    <Typography>Hospital information</Typography>
                    {/* <ListItemText primary={text} /> */}
                  </ListItemButton>
                </ListItem>}
                {qrId?.modules.some((item) => item.moduleName === "housekeeping" && item.licenseStatus === "active") && qrId?.modules.some((item) => item.moduleName === "maintenance" && item.licenseStatus === "active") &&
       
                <ListItem  disablePadding>
                  <ListItemButton onClick={() => {navigate(`/${id}/housekeeping-maintenance`); setOpen(false)}}>
                    <Typography>Houskeeping & maintenance</Typography>
                    {/* <ListItemText primary={text} /> */}
                  </ListItemButton>
                </ListItem>}
                {qrId?.modules.some((item) => item.moduleName === "feedback" && item.licenseStatus === "active") &&
       
                <ListItem  disablePadding>
                  <ListItemButton onClick={() => {navigate(`/${id}/feedback`); setOpen(false)}}>
                    <Typography>Feedback</Typography>
                    {/* <ListItemText primary={text} /> */}
                  </ListItemButton>
                </ListItem>}
              
            </List>
            
            <Box sx={{position:'fixed', left:'13%', bottom:15}}>
            <img src={tellercommIcon} alt='ico' width={30}/>

            </Box>
          </Drawer>
        </Stack>
      </StyledToolbar>
    </StyledRoot >
  );
}
