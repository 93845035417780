import { useEffect, useState } from 'react';

// mui -------------------------
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, CircularProgress, Stack, Typography } from "@mui/material";

// assets
import logo from '../assets/logo.svg'
import hosInfo from '../assets/hos-info.svg'
import hkMain from '../assets/hk_mainten.svg'
import feedback from '../assets/feedback.svg'

// icons--------------------------------
import { FaRegHospital } from "react-icons/fa";
import { RiTodoLine, RiFeedbackLine } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
//router dom
import { Link, useNavigate, useParams } from "react-router-dom";
import CustomSnackBar from '../components/snackbar';
import { useContext } from 'react';
import { QrContext, QrDispatchContext } from '../provider';
import { getHospitalDetails } from '../api/auth';
import ResponsiveAppBar from '../components/Appbar';
import AppFooter from '../components/BottomNavigation';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


export default function Landing() {

  const {id} = useParams()
  const navigate = useNavigate();
  const path = window.location.pathname.split('/')[1]
  const { qrId } = useContext(QrContext);
  const { setQrDetails, setPowerUsers, setFeedbackSection, setQrId } = useContext(QrDispatchContext);


  localStorage.setItem('qrId', path)

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const getLocationString = async () => {
    console.log(path);
    try {
      const { data } = await getHospitalDetails(path)
      if (data.success) {
        // setIsLoading(false)
        if (data.result.output.status !== 'active') {
          setSnackbarMessage({ msg: "QR is not active please contact admin", success: false });
          setSnackbarOpen(true);
          return
        }
        setQrDetails(data.result.output)
        setPowerUsers(data.result.powerUsers)
        setQrId(data.result.list)
        setFeedbackSection(data.result.feedbackSectionTypes)
        console.log('heer', data.result.list.modules.filter(element => { return element.licenseStatus === 'active' }));
      }
      else {
        setSnackbarMessage({ msg: "Request failed", success: false });
        setSnackbarOpen(true);
      }

    }
    catch (error) {
      // setIsLoading(false)
      console.log("err ", error);
      setSnackbarMessage({ msg: error.response.data ? error.response.data.message : error.message, success: false });
      setSnackbarOpen(true);
    }

  }

  useEffect(() => {
    getLocationString();
  }, [])

  const handleNavigate = (link) => {
    navigate(link)
  }
  // sx={{backgroundColor:'#454'}}
  return (
    <Box sx={{ width: '100vw', height: {xs:'90vh', sm:'96vh'}, mt:2 }}>

      <Stack spacing={2} display='flex' alignItems='center' flexDirection='column'>
        {qrId?.modules.some((item) => item.moduleName === "hospitalinformation" && item.licenseStatus === "active") &&
          <Grid item xs={12} sm={6} md={4} >
              <Box  onClick={() => handleNavigate(`/${id}/hospital-information`)} display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #0056FD', borderRadius: 3, maxWidth: '250px', height: '180px', p: 2, cursor: 'pointer' }}><img src={hosInfo} alt="hi" width={230} height={160}/></Box>
              <Typography align='center' >Hospital Information</Typography>
            
          </Grid>}
          {qrId?.modules.some((item) => item.moduleName === "housekeeping" && item.licenseStatus === "active") && qrId?.modules.some((item) => item.moduleName === "maintenance" && item.licenseStatus === "active") &&
       
          <Grid item xs={12} sm={6} md={4} >
              <Box onClick={() => handleNavigate(`/${id}/housekeeping-maintenance`)} display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #0056FD', borderRadius: 3, maxWidth: '250px', height: '180px', p: 2, cursor: 'pointer' }}><img src={hkMain} alt="hi"  height={140} width={230}/></Box>
              <Typography align='center' >Housekeeping & Maintenance</Typography>
        
          </Grid>}
          {qrId?.modules.some((item) => item.moduleName === "feedback" && item.licenseStatus === "active") &&
       
          <Grid item xs={12} sm={6} md={4} >
            
              <Box onClick={() => handleNavigate(`/${id}/feedback`)} display='flex' justifyContent='center' alignItems='center' sx={{ border: '2px solid #0056FD', borderRadius: 3, maxWidth: '250px', height: '180px', p: 2, cursor: 'pointer' }}><img src={feedback} alt="hi" height={160} width={230} /></Box>
              <Typography align='center' >Feedback</Typography>
         
          </Grid>}
      </Stack>

      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />
    </Box>
  );
}
