import axios from 'axios'
import { useEffect, useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
// import OtpInput from 'react-otp-input';
import OTPInput, { ResendOTP } from "otp-input-react";

// mui -------------------------
import { experimentalStyled as styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Unstable_Grid2";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Stack, TextField, Typography, Container, LinearProgress } from "@mui/material";

// assets

import sadIcon from '../assets/sadIcon.svg'

// api
import { firebaseAuth } from "../api/config";


// icons--------------------------------
import { FaRegHospital } from "react-icons/fa";
import { RiTodoLine, RiFeedbackLine } from "react-icons/ri";
import { GiBroom } from "react-icons/gi";
//router dom
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import AppFooter from '../components/BottomNavigation';
import CustomSnackBar from '../components/snackbar';
import successImage from '../assets/success_form.png'
import { ArrowBack } from '@mui/icons-material';
import { callOption } from '../api/auth';
import { useContext } from 'react';
import { QrContext } from '../provider';

export default function SuccessForm() {
  const { id } = useParams()
  const navigate = useNavigate();
  const location = useLocation();
  const { qrDetails, qrId } = useContext(QrContext);

  const [isLoading, setIsLoading] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [snackbarMessage, setSnackbarMessage] = useState(null)

  const [warningState, setWarningState] = useState(false)
  const handleClickYes = async () => {
    try {
      const { data } = await callOption({ callOption: true }, qrDetails.hospitalId, location.state.requestId)
      if (data.success) {
        console.log({ data });
      }
      setIsLoading(false)
    }
    catch (err) {
      console.log(err);
      setSnackbarMessage({ msg: err.message, success: false });
      setSnackbarOpen(true);
      setIsLoading(false)
    }
    setWarningState(false)
  }
  const handleClickNo = () => {
    setWarningState(false)
  }
  useEffect(() => {
    if (location.state?.warning) {
      setWarningState(true)
    }
  }, [])
  return (
    <Box sx={{ height: '100%' }} >
      <Grid display='flex' alignItems='center' flexDirection='column' height='100%' >
        {warningState ?
          <Stack p={1} spacing={5}>
            <Typography>you seem to be really unhappy, can we call you to know more and resolve it?</Typography>
            <Stack sx={{ display: 'flex', alignItems: 'center' }} spacing={2}>
              <img src={sadIcon} alt='ico' width={120} />
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }} width={160}>
                <Button variant='contained' sx={{ background: '#32B34B', color: 'white', borderRadius: 2 }} onClick={handleClickYes}>Yes</Button>
                <Button variant='contained' sx={{ background: '#DD2427', color: 'white', borderRadius: 2 }} onClick={handleClickNo}>No</Button>
              </Box>
            </Stack>
          </Stack>
          :
          <Stack spacing={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img src={successImage} alt='img' width={300} />
            <Stack sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Typography variant='h4'>Submitted successfully!</Typography>
              <Typography variant='h6'>We're working on it!</Typography>
            </Stack>
            <Button variant='contained' sx={{ backgroundColor: '#000', borderRadius: 8 }} startIcon={<ArrowBack color='inherit' />} onClick={() => { navigate(`/${id}/features`) }}>Home</Button>
          </Stack>
        }
      </Grid>
      <AppFooter />
      <CustomSnackBar snackbarOpen={snackbarOpen} setSnackbarOpen={setSnackbarOpen} snackbarMessage={snackbarMessage && snackbarMessage.msg} success={snackbarMessage && snackbarMessage.success} />

    </Box>
  );
}
